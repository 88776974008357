export const gcp_project_id="development-kainic";
export const gcp_region="us-central1"
export const gcp_cloud_functions = `https://${gcp_region}-${gcp_project_id}.cloudfunctions.net`
export const rest_analytics_version=`${gcp_cloud_functions}/api/v1/`
export const gcp_lake_bucket=`${gcp_project_id}_lake`
export const gcp_uploads_bucket=`${gcp_project_id}_uploads`
export const tracker_dashboard='https://datastudio.google.com/embed/reporting/a3d5ae46-e206-42f4-b3ca-6cfc7d2313ee/page/afFHC'
export const active_sesstion='https://datastudio.google.com/embed/reporting/038b3065-95e3-47e6-bbbb-d543e7535e82/page/afFHC'
export const mini_tracker_dashboard='https://datastudio.google.com/embed/reporting/eb364af3-109f-4d4b-a76f-64e9faab7034/page/pz0fC'
export const mini_model_dashboard='https://datastudio.google.com/embed/reporting/0a17b672-e6fe-4ff7-8f02-a0cd73abd49d/page/pz0fC'
export const client_coverage_dashboard='https://lookerstudio.google.com/embed/reporting/42dcee98-6af0-4e8f-a001-8e95ebdbcd3d/page/p_tc5qx2hm6c'
export const model_experiment_dashboard='https://datastudio.google.com/embed/reporting/88a8a3ef-8561-42ae-a7b1-cb23c32edd4c/page/NuhcC';
export const recommendation_widget_dashboard='https://lookerstudio.google.com/embed/reporting/ab0d96b7-4b3a-48a7-b325-1b5ec2d40a90/page/p_tc5qx2hm6c';
export const billing_dashboard= 'https://lookerstudio.google.com/embed/reporting/9bd90a5e-f7db-4050-a09f-a90e39aa30ec/page/NuhcC';
export const detailed_billing_dashboard= 'https://lookerstudio.google.com/embed/reporting/1cd8d6dc-ae85-48fa-a1f9-9bbad0791933/page/NuhcC'
export const get_shopify_products_container = 'https://get-spf-products-hih3ot5w5q-uc.a.run.app';
export const stripe_public_key= "pk_test_51QHQ40CmLzuwJ77HxQ4iOJ63uVCmhp5Fotjr5mpDj4SBA1z69uwl2ht2JTMeZ9ZRX0ZeKMkVpfndA2vKWVgQbviC00AXfZlNbC";
export const environment = {
  production: false,
  defaultauth: 'firebase',
  firebaseConfig: {
    apiKey: "AIzaSyA4WO13skpXo6ktQ3Jnl2xnSrnbqlj7cIw",
    authDomain: `${gcp_project_id}.firebaseapp.com`,
    projectId: gcp_project_id,
    storageBucket: `${gcp_project_id}.appspot.com`,
    messagingSenderId: "1032977942433",
    appId: "1:1032977942433:web:07bfcbf9dfad3ba39fade0",
    measurementId: "G-W09V25D539"
  }
};
